import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCookies } from 'react-cookie';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

export default function Login() {
    const navigate = useNavigate()
    const [keyState, setKeyState] = useState('')
    const [loginState, setLoginState] = useState('')
    const [pwdState, setPwdState] = useState('')
    const [cookies, setCookie] = useCookies(['operator_id', 'customer_id', 'token']);
    useState(() => {
        if(cookies.token)  {
            navigate('/')
        }
    }, [])

    function handleSubmit(e){
        e.preventDefault()
        console.log(keyState)
        console.log(loginState)
        console.log(pwdState)
        if(keyState !== '' && loginState !== '' && pwdState !== '') {
           const customer_data = base64_encode(JSON.stringify({operator_id: 2, customer_id: 1, key: 110, login: 'makarkin', password: 'KA2x5kYA'}));
            let expires = new Date();
            expires.setTime(expires.getTime() + 86400000);
            setCookie('token', customer_data, {path: '*', expires})
            localStorage.setItem('userAuth', 'true')
            navigate('/')
        }
    }
    return (
        <div id="auth-wrapper">
            <div id="auth-header-text">Авторизация</div>
            {/* <div > */}
                <form id="auth-form-wrapper" onSubmit={handleSubmit}>
                    <input name="key" type="text" id="auth-key-input" className="auth-input" placeholder="Ключ доступа" onChange={(e) => {setKeyState(e.target.value)}} required/>
                    <input name="login" type="text" id="auth-login-input" className="auth-input" placeholder="Логин" onChange={(e) => {setLoginState(e.target.value)}} required/>
                    <input name="pwd" type="text" id="auth-pwd-input" className="auth-input" placeholder="Пароль" onChange={(e) => {setPwdState(e.target.value)}} required/>
                    <input type="submit" id="auth-btn" value="Авторизация"/>
                </form>
            {/* </div> */}
        </div>
    )

}