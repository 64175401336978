import axios from "axios";

const send = axios.create({
    baseURL: 'https://app.idgos.ru/phpApp/router.php',
})

const sendRequest = async (url, json) => {
    const response = await send.post(url, json);
    return response.data;
}

export default sendRequest