import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { SizeMe } from 'react-sizeme';
import { Modal} from "react-bootstrap";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function PdfViewer(props) {
  const file="temporary/normyinternet.pdf"
   const file1="temporary/normyinternet1.pdf"

  const [totalPages, setTotalPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => setTotalPages(numPages);

  const signEsiaFile = () => {
    props.hide()
  }
  return ( <Modal
    show={props.show}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
    <SizeMe
      monitorHeight
      refreshRate={128}
      refreshMode={"debounce"}
      render={({ size }) => ( 
    <div >
      <Document
        file={{ url: file }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={''}
        >
        {Array.apply(null, Array(totalPages))
          .map((x, i) => i + 1)
          .map((page) => <Page width={size.width} key={page} pageNumber={page} />)}
      </Document>
      <Document
        file={{ url: file1 }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={''}
        >
        {Array.apply(null, Array(totalPages))
          .map((x, i) => i + 1)
          .map((page) => <Page width={size.width} key={page} pageNumber={page} />)}
      </Document>
       <div id="sign-btn" type="button" className="btn mb-3" onClick={signEsiaFile}>Подписать</div>
    </div>
  )}
  />
  </Modal>
  )
  
}

export default PdfViewer;
