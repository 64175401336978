import { useEffect } from "react";
import { setEsiaData } from "../functions/function";
import { useSearchParams } from "react-router-dom";
import { ServerApi } from "../api/ServerApi";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../functions/function";

//Подписание документов. Создание pdf и sig. Страница с лоадером
export default function SignProcessPage() {
  const serverApi = new ServerApi();
  const navigate = useNavigate();

  const signFile = async () => {
    const body = { action: "getCertInfo", data: { sertClientId: "СИНТЕГРА" } };
    const certInfo = await serverApi.sendPostRequest(body);
    const certDataResp = certInfo.data;
    let certData = {};
    if (certDataResp.success) certData = certDataResp.data;
    const documents = JSON.parse(localStorage.getItem("documents"));
    const encodedData = localStorage.getItem("esiaDataEncrypt");
    const esiaData = JSON.parse(decrypt(encodedData));
    // const esiaData = JSON.parse(localStorage.getItem("esiaData"));
    const token = esiaData.token_data.token;
    const token_date = esiaData.token_data.timestamp.iat;
    const personInfo = esiaData.personInfo;
    const fio = `${personInfo.lastName} ${personInfo.firstName} ${personInfo.middleName}`;
    const operator_id = localStorage.getItem("operator_id");
    const customer_id = localStorage.getItem("user_id");

    documents.forEach((doc, index) => {
      let templateFile = doc.path;
      let reqData = {
        action: "createSignedPdf",
        data: {
          operator_name: "СИНТЕГРА",
          operator_cert: certData.serialNumber,
          operator_cert_validDate: certData.validDate,
          filePath: templateFile,
          token: token,
          token_date: token_date,
          fio: fio,
          operator_id: operator_id,
          customer_id: customer_id,
          upload_id: doc.upload_id,
          last: false,
        },
      };
      if (index == documents.length - 1) {
        reqData.data.last = true;
      }
      serverApi.sendPostRequest(reqData).then((response) => {
        console.log(response);
        if (index == documents.length - 1) {
          navigate("/signed");
        }
      });
    });
  };

  useEffect(() => {
    signFile();
  }, []);

  return (
    <div id="main-page-wrapper">
      <div className="justify-content-center" id="sign_loader">
        <div className="">
          <h4 className="text-center loader-text">Подписание документов</h4>
          <div className="progress">
            <div className="progress-bar">
              <div className="progress-shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
