import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import './MyModal.css'
import { useNavigate } from 'react-router-dom'
import { ServerApi } from '../api/ServerApi'
import { signFile } from '../functions/function'

export default function MyModal(props) {
 
    const [isDisabled,setIsDisabled] = useState(true)
    const [value, setValue] = useState('');
    const inputRef = useRef(null);
    const navigate = useNavigate()
    const serverApi = new ServerApi();
  
    const handleChange = (e) => {
      const newValue = e.target.value.replace(/\D/g, '').slice(0, 4);
      setValue(newValue);
      const user_id = localStorage.getItem('user_id')
      if(newValue.length === 4) {
        serverApi.checkCode(user_id, newValue).then((response) => {
            console.log(response)
            if(response.status === 200) {
                if(response.data.success ) {
                  setIsDisabled(false);
                    
                   
                }
            }
        })
      }
    };
  
    useEffect(() => {
        if(props.show){
            inputRef.current.focus();
        }
    }, [props]);
    const inputElement = useRef(null);

    useEffect(() => {
      if (inputElement.current) {
        inputElement.current.style['caretColor'] = 'transparent';
        inputElement.current.style['webkitTextSecurity'] = 'none';
        inputElement.current.style['textSecurity'] = 'none';
      }
    }, []);
    const clickHandler = () => {
        props.onHide()
        // signFile(previewImagesState)
        navigate('/signdocs')
    }
  
  return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                Введите код из СМС
            </Modal.Title>
            <button type="button" class="btn-close" aria-label="Close" onClick={props.click}></button>
        </Modal.Header>
        <Modal.Body>
            <h4 className='my-modal-title'>Наш ваш номер телефона было выслано смс с кодом</h4>
            <div className="code-input-container">
            <input
                ref={inputRef}
                type="number"
                maxLength="4"
                value={value}
                onChange={handleChange}
                className="code-input"
                inputMode="numeric"
            />
            <div className="visual-fields">
                {[...Array(4)].map((_, index) => (
                <span key={index} className={`field ${value[index] ? 'filled' : ''}`}>
                    {value[index]}
                </span>
                ))}
            </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={clickHandler} style={{
                backgroundColor: isDisabled ? 'rgb(56 141 168 / 28%)' : '#388DA8',
                color: isDisabled ? '#666' : '#fff',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                border:'0px',
                width:'100%',
                height:'52px',
                fontSize:'16px',
                lineHeight:'24px',
                fontWeight:'400',
                borderRadius:'12px',
                padding:'14px 32px 14px 32px'
            }}>Подтвердить</Button>
        </Modal.Footer>
    </Modal>
    )
}
