import React ,{useEffect, useState}from 'react'
import './style/view.css'
import { useNavigate } from 'react-router-dom';
import { ServerApi } from '../api/ServerApi';
import refreshIcon from "../resourse/icons/refresh.svg";

export default function ViewPage() {
  const navigate = useNavigate()
  const operator_name = localStorage.getItem('operator_name')
  const serverApi = new ServerApi()
  const [esiaUrl, setEsiaUrl] = useState(false)


  // useEffect(() => {
    //Прверка наличия не подписанных и сформированных документов(статусы 0,1,2). При отсутствии - перенаправление на стр. с подписанными док.
  //   const user_id = localStorage.getItem('user_id')
  //   const operator_id = localStorage.getItem('operator_id')
  //   const body = {
  //     action: 'checkNoSignedDocs',
  //     data: {
  //       user_id: user_id,
  //       operator_id: operator_id
  //     }
  //   }
  //   serverApi.sendPostRequest(body).then((response) => {
  //     console.log(response)
  //     if(response.status === 200) {
  //       if(response.data.documents.length == 0) navigate('/signed')
  //     }
  //   })
  // }, [])
 

  const nextStep = () => {
    setEsiaUrl(true)
    //Формирование ссылки ЕСИА и перенаправление на форму авторизации 
    serverApi.getUrl().then((response) => {
      console.log(response.data.url)
      window.location.replace(response.data.url)
    })
  }

  return (
    <div id='view-page'>
        <div id='view-block'>
          <div className='view-img-block'>
            <img src='../icons/logo-idgos.png' alt='!#' className='logo-idgos'/>
          </div>
          <div className='view-text-block'>
            <p className='view-subtitle'>
              Уважаемый Абонент, Вы находитесь в Сервисе для заключения договора на Услуги связи с Компанией {operator_name}
            </p>
            <h1 className='view-title p-4'>Подписание договора на услуги будет в три этапа, на раз два три</h1>
          </div>
          <div className='view-step-block'>
            <div className='step1'>
              <h1 className='view-title'>1</h1>
              <p className='view-subtitle1'>Авторизация (Госуслуги)</p>
            </div>
            <div className='step2'>
              <h1 className='view-title'>2</h1>
              <p className='view-subtitle1'>Ознакомление с договором</p>
            </div>
            <div className='step3'>
              <h1 className='view-title'>3</h1>
              <p className='view-subtitle1'>Подписание через СМС</p>
            </div>
          </div>
          <div className='view-text-block'>
            <p className='view-subtitle mx-2'>
            Нажимая продолжить Вы даёте согласие на обработку Персональных данных {operator_name}, а также его коммерческому представителю ООО «Синтегра» 
            </p>
          </div>
          <div className='fixed-bottom' style={{display:'flex' , width:'90%' , justifyContent:'center' }}>

            

            <button 
            className={'view-btn'}
            onClick={nextStep}
            disabled={esiaUrl}
            >
            <div className={esiaUrl ? '' : 'd-none'} id={'loader-cont'}>
              <img className="objRotate mr-5 loading" id={'balance-loading'} src={refreshIcon}
                alt={'refreshIcon'}
                width="15px"/>
              <h6 id='redirect-title'>{'Перенаправление на Госуслуги'}</h6>
            </div>
            <div id={'nextStep'} className={esiaUrl ? 'd-none' : ''}>Продолжить</div>
              
            </button>
            </div>
        </div>
    </div>
  )
}
