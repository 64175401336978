import React, { useEffect, useState } from "react";
import "./style/greeting.css";
import CheckBox from "../Components/CheckBox";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { ServerApi } from "../api/ServerApi";
import { Modal } from "react-bootstrap";
import refreshIcon from "../resourse/icons/refreshColor.svg";
import CryptoJS from "crypto-js";
import axios from "axios";

export default function GreetingsPage() {
  const serverApi = new ServerApi();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [visibleStatus, setVisibleStatus] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies([
    "operator_id",
    "user_id",
    "esia_url",
    "esia_token",
    "link_token",
  ]);
  const operator_id = searchParams.get("c");
  const user_id = searchParams.get("u");
  const [modalOpen, setModalOpen] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked((prevState) => !prevState);
    setIsDisabled(!isDisabled);
  };
  const handleButtonClick = () => {
    if (!isDisabled) {
      navigate("/view");
    }
  };

  useEffect(() => {
    //Не переданы данные в ссылке
    if (!operator_id || !user_id) {
      setVisibleStatus("d-none");
    } else {
      //Проверка разрешения на вход по ссылке. Проверяется временная метка. Действительна сутки
      const link_date = Date.now() / 1000;
      const body = {
        action: "getLinkActiveDate",
        data: {
          user_id: user_id,
          operator_id: operator_id,
          link_date: link_date,
        },
      };
      serverApi.sendPostRequest(body).then((response) => {
        if (response.status === 200) {
          if (!response.data.success) {
            document.getElementById("disable_link").classList.remove("d-none");
            setVisibleStatus("d-none");
            localStorage.clear();
          }
        }
      });
    }
    localStorage.clear()
  }, []);

  useEffect(() => {
    // localStorage.clear();
    // removeCookie("link_token");
    // removeCookie("esia_token");
    if (operator_id && user_id) {
      localStorage.setItem("operator_id", operator_id);
      localStorage.setItem("user_id", user_id);
      //Название компании
      serverApi.getOperatorInfo(operator_id).then((response) => {
        if (response.status == 200) {
          localStorage.setItem("operator_name", response.data.name);
        }
      });
      //Скорее всего не нужно в дальнейшем
      // let token_exp = new Date();
      // token_exp.setTime(token_exp.getTime() + 86400000);
      // const link_token = base64_encode(JSON.stringify({operator_id: operator_id, user_id: user_id, token_exp:token_exp}));
      // setCookie('link_token', link_token, {path: 'https://app.idgos.ru', expires: token_exp})
    }
  }, [searchParams]);
  const [currIp ,setCurrIp]=useState('')
  useEffect(()=>{
    const fetchData = async () => {
      try {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const countryResponse = await axios.get(`https://ipapi.co/${ipResponse.data.ip}/json/`);
        
        setCurrIp(countryResponse.data.country);
      } catch (error) {
        console.error('Произошла ошибка при получении данных:', error);
        setCurrIp(null); // или любое другое значение по умолчанию
      }
    };
  
    fetchData();
  },[])
 

  return (
    <>
      <div id="greeting">
        <div id="greeting-block" className="mx-2">
          <div className="greeting-img-block">
            <img
              src="../icons/logo-idgos.png"
              alt="!#"
              className="logo-idgos"
            />
          </div>
          <div className="greeting-text-block">
            <h1 className="greeting-title">Добро пожаловать IDGOS</h1>
            <p className="greeting-subtitle">
              Сервис для подписания документов через ГосУслуги
            </p>
            <p
              className="greeting-subtitle d-none"
              id="disable_link"
              style={{ color: "red", fontWeight: "bold" }}
            >
              Ваша ссылка просрочена
            </p>
          </div>
          {/* <div className='greeting-input-block'>
                <input 
                    className='greeting-input'
                    type="checkbox" 
                    checked={isChecked} 
                    onChange={handleCheckboxChange}
                />
                <span className='greeting-input-text'>Я согласен с политикой конфиденциальности</span>
            </div> */}
          {currIp == 'RU' ?
          <>
          <div className={visibleStatus}>
            <CheckBox
              label="Я согласен с политикой конфиденциальности"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
          </div>

          <div className={`greeting-btn-block ${visibleStatus}`}>
            <button
              onClick={handleButtonClick}
              disabled={isDisabled}
              style={{
                backgroundColor: isDisabled
                  ? "rgb(56 141 168 / 28%)"
                  : "#388DA8",
                color: isDisabled ? "#666" : "#fff",
                border: "none",
                borderRadius: "12px",
                padding: "14px 32px 14px 32px",
                cursor: isDisabled ? "not-allowed" : "pointer",
              }}
            >
              Продолжить
            </button>
          </div>
          </> :
          currIp == '' ? <img style={{width:'30px'}} src="./icons/VAyR.gif"/>:
          <p>Доступ закрыт, возможно вы используете VPN</p>
          }
        </div>
      </div>
      {/* <Modal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <h4 style={{ color: "white" }}>Загрузка</h4>
        </Modal.Title>
        <Modal.Body>
          <div class="loader-container">
            <img
              className="objRotate mr-5 loading"
              id={"balance-loading"}
              src={refreshIcon}
              alt={"refreshIcon"}
              width="50px"
            />
            <h5 class="loader-text">Пожалуйста, подождите</h5>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal> */}
    </>
  );
}
